const {fetchData} = require('@/helpers/featch');

export const state = {
    voucherStatus: {},
};

export const getters = {
    voucherStatus: state => state.voucherStatus,
};

export const mutations = {
    SET_VOUCHER_STATUS(state, data) {
        state.voucherStatus = data;
    }
};

export const actions = {

    async voucherCheck({commit}, voucherHash) {
        const body = JSON.stringify({voucherHash});
        const {data} = await fetchData.post("/CheckVoucher", body);

        if (data) {
            let object = {...data};

            if("voucher" in data === true) {
                object = {...data['voucher'], code: data.code};
            }

            commit("SET_VOUCHER_STATUS", object);

            return object;
        }

        return false;
    },

    /* eslint-disable */
    async realizeVoucher({commit}, voucherHash) {
        const body = JSON.stringify({voucherHash});
        const {data} = await fetchData.post("/RealizeVoucher", body);

        if (data) {

            return data;
        }

        return false;
    },

    /* eslint-disable */
    async cancelReservation({commit}, voucherHash) {
        const body = JSON.stringify({voucherHash});
        const {data} = await fetchData.post("/CancelReservation", body);

        if (data) {

            return data;
        }

        return false;
    },

    /* eslint-disable */
    async reserveVoucher({commit}, voucherHash) {
        const body = JSON.stringify({voucherHash});
        const {data} = await fetchData.post("/ReserveVoucher", body);

        if (data) {

            return data;
        }

        return false;
    },

    /* eslint-disable */
    async planRealization({commit}, payload) {
        const body = JSON.stringify(payload);
        const {data} = await fetchData.post("/PlanRealization", body);

        if (data) {

            return data;
        }

        return false;
    },
};
