import { Capacitor } from '@capacitor/core'

const setCookie = (name, value, minutes) => {
    const d = new Date();
    d.setTime(d.getTime() + (minutes * 60  * 1000));
    let expires = "expires=" + d.toUTCString();

    if(Capacitor.isNativePlatform()) {
        return localStorage.setItem(name, value);
    }

    document.cookie = '_'+name + "=" + value + ";" + expires + ";secure;SameSite=Lax;path=/;";
};

const getCookie = (name) => {
    let cookie = {};
    document.cookie.split(';').forEach(function(el) {
        let [key, value] = el.split('=');
        cookie[key.trim()] = value;
    })

    if (Capacitor.isNativePlatform()) {
        return localStorage.getItem(name);
    }

    return cookie['_' + name];
};

const removeCookie = async (name) => {
    if (Capacitor.isNativePlatform()) {
        localStorage.removeItem(name);
    }

    document.cookie = '_' + name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;secure;SameSite=Lax;path=/;";
}


export {setCookie, getCookie, removeCookie};
