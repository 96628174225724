const {fetchData} = require('@/helpers/featch');

export const state = {
    reservations: [],
    pagesCount: 1,
};

export const getters = {
    reservations: (state) => state.reservations,
    pagesCount: (state) => state.pagesCount
};

export const mutations = {
    SET_RESERVATIONS(state, data) {
        state.reservations = data;
    },

    SET_PAGES_COUNT(state, value) {
        state.pagesCount = value;
    },
};

export const actions = {
    async getVouchers({commit, dispatch}, payload) {
        try {
            dispatch("app/updateIsBusy", true, { root: true });
            const params = new URLSearchParams(payload).toString();
            const {data} = await fetchData.post("/GetReservedVouchers", {}, params);

            if (data) {
                const onPage = payload.on_page ? payload.on_page : 100;
                const pagesCount = Math.ceil( data.itemsCount / onPage);
                commit('SET_RESERVATIONS', data.items);
                commit('SET_PAGES_COUNT', pagesCount);

                return data.items;
            }
        } catch (e) {
            console.error(e);
        }finally {
            dispatch("app/updateIsBusy", false, { root: true });
        }
    }
};
