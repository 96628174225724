<template>
  <v-bottom-sheet v-model='updateExists' persistent>
    <v-sheet class='text-center d-flex align-center flex-column justify-center' height='200px'>
      <div class='my-3'>Dostępna jest aktualizacja aplikacji. Kliknij przycisk "Zainstaluj".</div>

      <v-btn class='ml-4' @click='refreshApp' color='secondary' x-large dark>
        Zainstaluj
      </v-btn>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>

import update from '@/mixins/update'

export default {
  mixins: [update],

  mounted() {}
}

</script>
