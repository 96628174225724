import Vue from 'vue'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import colors from 'vuetify/lib/util/colors'
import pl from 'vuetify/lib/locale/pl'
import Vuetify from 'vuetify'

Vue.use(Vuetify)

const getMediaPreference = () => {
    const theme = localStorage.getItem("user-theme");
    const hasDarkPreference = window.matchMedia(
        "(prefers-color-scheme: dark)"
    ).matches;

    if (theme) {
        return theme === 'dark-theme' ? true : false;
    } else {
        return hasDarkPreference;
    }
};

const opts = {
    lang: {
        locales: {pl},
        current: 'pl',
    },
    theme: {
        dark: getMediaPreference(),
        themes: {
            light: {
                emotivo: "#2D343D",
                primary: colors.blue.base,
                secondary: '#169d07',
                accent: colors.indigo.base, // #3F51B5
                red: '#E53935',
                whiten: '#4A148C',
                backgroundInvert: '#1e1e1e'
            },
            dark: {
                emotivo: "#2D343D",
                primary: colors.blue.lighten1,
                secondary: '#1dc20b',
                accent: colors.indigo.base, // #3F51B5
                red: '#ff403c',
                light: "#ac83f5",
                dark: "#3f218f",
                background: '#1e1e1e',
                backgroundInvert:'#f1f3f4',
                whiten: '#F3E5F5',
                'grey-darken-4':'#212121',
                'grey-darken-3':'#424242',
                'grey-lighten-1':'#BDBDBD'
            },
        }
    },
}

export default new Vuetify(opts)
