<template>

  <div class="d-flex justify-center" :style="{ 'transform': `scale(${getScale})` }">

    <div class="welcome-container align-center justify-center flex-column"
         :style="{ backgroundImage: 'url(' + require('@/assets/background.png') + ')' }">
      <h2 class="white--text text-shadow">Poznaj naszą nową aplikację</h2>
      <img :src="image" alt="reklama aplikacji mobilnej"/>
      <div>
        <a href="https://play.google.com/store/apps/details?id=com.prezentmarzenapp&gl=PL" target="_blank"><img
            src="@/assets/google-play-badge.png" width="120px" alt="google play"/></a>
        <a href="https://apps.apple.com/gb/app/aplikacja-wykonawcy-emotivo/id6443602246" target="_blank"><img
            src="@/assets/app-store-badge.png" width="120px" alt="google play"/></a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    img: {
      type: String,
      default: 'iphone.png'
    }
  },

  data() {
    return {}
  },

  computed: {
    image() {
      return require(`@/assets/${this.img}`);
    },

    getScale() {
      if (this.$vuetify.breakpoint.width < 436) {
        return this.$vuetify.breakpoint.width / 622;
      } else {
        return 1;
      }
    }
  },

  methods: {},

  mounted() {

  }
}
</script>
<style lang="scss" scope>
.welcome-container {
  display: flex;
  border-radius: 11px;
  max-height: 623px;
  max-width: 600px;
  min-width: 500px;
  padding: 40px;
}

.text-shadow {
  text-shadow: 2px 2px 7px #0a0a0a;
}
</style>
