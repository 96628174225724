import { appserviceFetchData } from '@/helpers/featch'
import { normalizeRealizedVouchersList } from '@/normalizers/realizedVouchers'
import contractType from '@/helpers/contractType'

const { fetchData } = require('@/helpers/featch')

export const state = {
  vouchers: [],
  pagesCount: 1,
  itemsCount: 0,
  itemsInternalCount: 0,
  params: {
    page: 1,
    order_way: 'desc',
    on_page: 10,
    per_page: 10,
    date_from: '',
    date_to: ''
  }
}

export const getters = {
  vouchers: (state) => state.vouchers,
  pagesCount: (state) => state.pagesCount,
  itemsCount: (state) => state.itemsCount,
  itemsInternalCount: (state) => state.itemsInternalCount,
  params: (state) => state.params
}

export const mutations = {
  SET_PAGE(state, data) {
    state.params.page = data
  },

  SET_FILTER(state, { key, value }) {
    state.params[key] = value
    state.params['filter[' + key + ']'] = value
  },

  SET_VOUCHERS_AND_MERGE(state, data) {
    state.vouchers = [...state.vouchers, ...data]
  },

  SET_VOUCHERS(state, data) {
    state.vouchers = [];
    state.vouchers = data
  },

  SET_PAGES_COUNT(state, data) {
    state.pagesCount = data
  },

  SET_ITEMS_COUNT(state, data) {
    state.itemsCount = data
  },

  SET_ITEMS_INTERNAL_COUNT(state, data) {
    state.itemsInternalCount = parseInt(data)
  }
}

export const actions = {
  async getRealizedVouchers({ commit, dispatch, rootState}, { payload, appService = false, merge = true }) {
    try {
      dispatch("app/updateIsBusy", true, { root: true });
      payload['contract_type'] = contractType(rootState.supplier.provisionSupplier)
      const params = new URLSearchParams(payload).toString()
      if (appService) {
        const { data } = await appserviceFetchData.get(`/reports/vouchers`, params)
        if (data) {
          const normalizedData = normalizeRealizedVouchersList(data)
          normalizedData.page > 1 && merge ? commit('SET_VOUCHERS_AND_MERGE', normalizedData.items) : commit('SET_VOUCHERS', normalizedData.items)
          commit('SET_PAGES_COUNT', normalizedData.totalPages)
          commit('SET_ITEMS_COUNT', normalizedData.itemsCount)
          commit('SET_ITEMS_INTERNAL_COUNT', normalizedData.itemsInternalCount)

          return data.items
        }
      }

      const { data } = await fetchData.post('/GetRealizedVouchers', null, params)
      if (data) {

        data.page > 1 && merge ? commit('SET_VOUCHERS_AND_MERGE', data.items) : commit('SET_VOUCHERS', data.items)
        commit('SET_PAGES_COUNT', data.totalPages)
        commit('SET_ITEMS_COUNT', data.itemsCount)
        commit('SET_ITEMS_INTERNAL_COUNT', data.itemsInternalCount)

        return data.items
      }

      return []
    } catch (e) {
      console.error(e)
    }finally {
      dispatch("app/updateIsBusy", false, { root: true });
    }
  },

  async clearRealizedVouchers({ commit }) {
    commit('SET_VOUCHERS', [])
    commit('SET_PAGES_COUNT', 0)
    commit('SET_ITEMS_INTERNAL_COUNT', 0)

    return []

  }
}
