import { getConstant } from '@/helpers/global'

export const statusCodeToReasonPhrase = {
  '401': 'UNAUTHORIZED',
  '500': 'INTERNAL_SERVER_ERROR',
  '1000': 'OK_CODE',
  '1001': 'RAPORT_DELETED_CODE',
  '2000': 'INVALID_REQUEST_CODE',
  '2001': 'OBJECT_NOT_FOUND_CODE',
  '2002': 'INVALID_CREDENTIALS_CODE',
  '2003': 'INVALID_TOKEN_CODE',
  '2004': 'TOKEN_EXPIRED_CODE',
  '3000': 'BUSINESS_ERROR_CODE',
  '3001': 'VOUCHER_STATUS_CODE',
  '3002': 'VOUCHER_IN_RESERVATION_CODE',
  '3003': 'VOUCHER_REALIZED_CODE',
  '3004': 'VOUCHER_SETTLED_CODE',
  '3005': 'VOUCHER_BLOCKED_CODE',
  '3006': 'VOUCHER_EXPIRED_CODE',
  '3007': 'VOUCHER_TYPE_CODE',
  '3008': 'VOUCHER_GIFTCARD_CODE',
  '3009': 'VOUCHER_BOX_CODE',
  '3010': 'VOUCHER_OTHER_SUPPLIER_CODE',
  '3013': 'EMPTY_SUPPLIER',
  '4000': 'INTERNAL_ERROR_CODE',
  '4001': 'COMMUNICATION_ERROR_CODE'
}

export const reasonPhraseToStatusCode = {
  'UNAUTHORIZED': '401',
  'INTERNAL_SERVER_ERROR': '500',
  'OK_CODE': '1000',
  'RAPORT_DELETED_CODE': '1001',
  'INVALID_REQUEST_CODE': '2000',
  'OBJECT_NOT_FOUND_CODE': '2001',
  'INVALID_CREDENTIALS_CODE': '2002',
  'INVALID_TOKEN_CODE': '2003',
  'TOKEN_EXPIRED_CODE': '2004',
  'BUSINESS_ERROR_CODE': '3000',
  'VOUCHER_STATUS_CODE': '3001',
  'VOUCHER_IN_RESERVATION_CODE': '3002',
  'VOUCHER_REALIZED_CODE': '3003',
  'VOUCHER_SETTLED_CODE': '3004',
  'VOUCHER_BLOCKED_CODE': '3005',
  'VOUCHER_EXPIRED_CODE': '3006',
  'VOUCHER_TYPE_CODE': '3007',
  'VOUCHER_GIFTCARD_CODE': '3008',
  'VOUCHER_BOX_CODE': '3009',
  'VOUCHER_OTHER_SUPPLIER_CODE': '3010',
  'EMPTY_SUPPLIER': '313',
  'INTERNAL_ERROR_CODE': '4000',
  'COMMUNICATION_ERROR_CODE': '4001'
}

export function getReasonPhrase(statusCode) {
  if(!statusCode){
    throw new Error(`Status code not found!`);
  }

  const result = statusCodeToReasonPhrase[statusCode.toString()]
  if (!result) {
    throw new Error(`Status code does not exist: ${statusCode}`)
  }
  return result
}

export function getStatusCode(reasonPhrase) {
  const result = reasonPhraseToStatusCode[reasonPhrase]
  if (!result) {
    throw new Error(`Reason phrase does not exist: ${reasonPhrase}`)
  }
  return result
}

export function success(statusCode) {
  if (statusCode === 1000) {
    return true
  }

  return false
}

export function getResponseMessage(statusCode, message) {
  try {
    const statusPhrase = getReasonPhrase(statusCode)
    if (!statusPhrase) {
      return message
    }

    const result = getConstant(`${statusPhrase}_MESSAGE`)

    if(!result && message){
      return message;
    }

    if (!result) {
      throw new Error(`Reason phrase does not exist: ${statusPhrase}`)
    }

    return result

  } catch (e) {
    return getConstant('OPERATION_FAILED');
  }
}

export function getResponseType(code) {
  const success = [200, 201, 1000];
  const info = [1001]

  if(info.includes(code)){
    return 'info';
  }

  if (success.includes(code)) {
    return 'success';
  }

  return 'error';
}

export const getStatusText = getReasonPhrase
