export const CREATED = 'Wygenerowany'
export const SEND = 'Wysłany'
export const ACCEPTED = 'Zatwierdzony'
export const DELETED = 'Usunięty'

export const REPORT_STATUSES_DICTIONARY = {
  CREATED: 1,
  SEND: 2,
  ACCEPTED: 3,
  DELETED: 4
}

export const REPORT_CODE_STATUSES = {
  1: CREATED,
  2: SEND,
  3: ACCEPTED,
  4: DELETED
}
