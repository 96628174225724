import { VOUCHER_CHECKER_MESSAGES, VOUCHER_CHECKER_STATUSES } from '@/constants';
import {getConstant} from "@/helpers/global";

const getVoucherMessage = (code = null, status = null, oldGR = false) => {
    if (oldGR) {
        return '<p>' + getConstant('OLD_GR_MESSAGE') + '</p>';
    }

    if (code && status) {
        if (VOUCHER_CHECKER_MESSAGES[status]) {
            return VOUCHER_CHECKER_MESSAGES[status];
        }
    }

    if (code && VOUCHER_CHECKER_MESSAGES[code]) {
        return VOUCHER_CHECKER_MESSAGES[code];
    }

    if (status && VOUCHER_CHECKER_MESSAGES[status]) {
        return VOUCHER_CHECKER_MESSAGES[status];
    }

    if (code && !VOUCHER_CHECKER_MESSAGES[code]) {
        return false;
    }
};

const getVoucherStatusName = (code = null, status = null) => {
    if (code && status) {
        if (VOUCHER_CHECKER_STATUSES[status]) {
            return VOUCHER_CHECKER_STATUSES[status];
        }
    }

    if (code && VOUCHER_CHECKER_STATUSES[code]) {
        return VOUCHER_CHECKER_STATUSES[code];
    }

    if (status && VOUCHER_CHECKER_STATUSES[status]) {
        return VOUCHER_CHECKER_STATUSES[status];
    }

    if (code && !VOUCHER_CHECKER_STATUSES[code]) {
        return false;
    }
};

/**
 * @deprecated since version 1.5.3
 */
const getResponseMessage = (code, message) => {
    if (message) {
        return message;
    }

    const availableCodes = [200, 201];

    if (VOUCHER_CHECKER_MESSAGES[code] && availableCodes.includes(code)) {
        return VOUCHER_CHECKER_MESSAGES[code];
    }

    return getConstant('OPERATION_FAILED');
};

/**
* @deprecated since version 1.5.3
 */
const getResponseType = code => {
    const availableCodes = [200, 201];

    if (availableCodes.includes(code)) {
        return 'success';
    }

    return 'error';
};

export {getResponseMessage, getResponseType};
export {getVoucherMessage, getVoucherStatusName};


