import { CONSTANTS } from '@/constants'


export const MODAL_MESSAGES = {
  REPORT_SEND: {
    title: 'Raport wysłany',
    message: CONSTANTS.SEND_REPORT_SUCCESS_MESSAGE
  },
  REPORT_SEND_FAILED: {
    title: CONSTANTS.SOMETHING_WENT_WRONG,
    message: CONSTANTS.SEND_REPORT_FAILED_MESSAGE
  },
  DELETE_VOUCHER: {
    title: 'Czy usunąć wybrany voucher?',
    message: 'Usuniecie spowoduje przeniesienie vouchera do zakładki "zrealizowane".'
  },
  DELETE_VOUCHER_FAILED: {
    title: CONSTANTS.SOMETHING_WENT_WRONG,
    message: 'Nie mogliśmy usunąć vouchera z raportu. Spróbuj jeszcze raz...'
  },
  DELETE_REPORT: {
    title: 'Czy chcesz usunąć raport?',
    message: 'Usuniecie raportu spowoduje przeniesienie zrealizowanych voucherów do zakładki "zrealizowane". W zakładce "zrealizowane" możesz ponownie wygenerować raport.'
  },

  DELETE_REPORTS: {
    title: 'Czy chcesz usunąć wybrane raporty?',
    message: 'Usuniecie raportów spowoduje przeniesienie zrealizowanych voucherów do zakładki "zrealizowane". W zakładce "zrealizowane" możesz ponownie wygenerować raport.'
  },

  DELETE_VOUCHERS: {
    title: 'Czy chcesz usunąć wybrane vouchery?',
    message: 'Usuniecie voucherów spowoduje przeniesienie voucherów do zakładki "zrealizowane".'
  },

  MARK_AS_REALIZED: {
    title: 'Czy oznaczyć wybrane numery jako zrealizowane?',
    message: 'Jeżeli oznaczysz wybrane numery jako zrealizowane, to przejdą one do listy numerów zrealizowanych i będą czekać na rozliczenie.'
  },

  CANCEL_RESERVATION: {
    title: 'Czy anulować rezerwacje?',
    message: 'Anulowanie rezerwacji zaznaczonych numerów usunie je z listy. Aby ponownie je zarezerwować będziesz musiał zeskanować lub wprowadzić kod na nowo.'
  }
}
