import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import Vuelidate from 'vuelidate';
import VueToast from "vue-toast-notification";
import router from './router/index'
import store from './store/index'
import moment from './plugins/moment'
import VueMeta from 'vue-meta'
import myPlugin from "./plugins/global-functions";
import "./plugins/axios";
import 'core-js/stable';
import './registerServiceWorker';
import './plugins/infiniteScroll';
import './plugins/hotjar';
import './plugins/sentry';

import App from './App.vue'
import 'vue-toast-notification/dist/theme-default.css';
import './style/smartbanners.css';

Vue.config.productionTip = false;
Vue.prototype.moment = moment;


Vue.use(VueMeta);
Vue.use(Vuelidate);
Vue.use(myPlugin);
Vue.use(VueToast, {
    position: 'top-right',
    duration: 5000,
    maxToasts: 5,
    draggable: true,
    pauseOnHover: true,
    pauseOnFocusLoss: true,
    closeOnClick: true,
});

new Vue({
    render: h => h(App),
    router,
    store,
    vuetify,
}).$mount('#app');
