<template>
  <v-bottom-sheet
    v-model='sheet'
    persistent
  >
    <v-sheet class='text-center d-flex align-center flex-column justify-center' height='200px'>
      <v-btn
        v-if='showCloseButton'
        class="mt-6"
        text
        color="error"
        @click="sheet = !sheet"
      >
        close
      </v-btn>
      <div v-if='!showCustomMessage' class='my-3'>Wykryto nową wersję oprogramowania. Trwa aktualizowanie. Proszę czekać...</div>
      <div v-if='showCustomMessage' class='my-3'>{{ customMessage }}</div>
      <v-progress-circular v-if='!showCloseButton' indeterminate color='primary' />
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
import { CapacitorUpdater } from '@capgo/capacitor-updater'
import { isNativePlatform } from '@/helpers/global'
import { setCookie, getCookie } from '@/helpers/cookies'

export default {
  data() {
    return {
      appVersion: null,
      sheet: false,
      showCloseButton: false,
      showCustomMessage: false,
      customMessage: '',
      noNeedUpdate: false
    }
  },

  computed: {},

  mounted() {
    if (!isNativePlatform()) {
      return
    }

    this.eventListener()
    CapacitorUpdater.notifyAppReady()
  },

  methods: {
    async eventListener() {
      CapacitorUpdater.addListener('download', () => {
        this.sheet = true;
        this.showCloseButton = false;
      })

      CapacitorUpdater.addListener('updateAvailable', async (res) => {
        this.appVersion = 'updateAvailable'
        this.sheet = false;
        try {
          await CapacitorUpdater.set(res.bundle)
        } catch (error) {
          console.log(error)
        }
      })

      CapacitorUpdater.addListener('downloadFailed', () => {
        this.sheet = true;
        this.showCloseButton = true;
        this.customMessage = 'Błąd pobierania danych. Aktualizacja nie powiodła się. Spróbuj ponownie później lub sprawdź czy pojawiła się nowa wersja aplikacji w sklepie.'
      })


      CapacitorUpdater.addListener('appReady', ({bundle}) => {
        setCookie('appVersion', bundle.version)
        this.sheet = false;
        if(bundle.version !== getCookie('appVersion')) {
          this.sheet = true;
          this.showCloseButton = true;
          this.showCustomMessage = false;
          this.appVersion = { v: bundle.version, s: getCookie('appVersion') }
        }
      })
    }
  }
}

</script>
