<template>
  <v-breadcrumbs class='px-0' :items='breadCrumbs'>
    <template v-slot:item='{ item }'>
      <v-breadcrumbs-item
        :to='item.to'
        class='text-subtitle-2 crumb-item'
        :disabled='item.disabled'
        exact
      >
        {{ item.text }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {

    ...mapGetters({
      supplier: 'supplier/supplier'
    }),

    getReportsRoute() {
      const { has_new_settlements } = this.supplier

      if (has_new_settlements === '0') {
        return { text: 'Raporty', to: { name: 'reports' } }
      }

      if (this.$route.name === 'report') {
        return { text: 'Raporty Archiwalne', to: { name: 'reports-archive' } }
      } else {
        return { text: 'Raporty', to: { name: 'reports' } }
      }
    },

    breadCrumbs() {
      const { $route } = this
      if ($route.name === 'report' || $route.name === 'appservice-report') {
        const { symbol, year, month, number } = $route.params

        return [
          { text: 'Sprawdź voucher', to: { name: 'dashboard' } },
          this.getReportsRoute,
          { text: `${symbol}/${year}/${month}/${number}` }
        ]
      }

      return [
        { text: 'Sprawdź voucher', to: { name: 'dashboard' } },
        { text: this.$route.name !== 'dashboard' ? this.$route.meta.title : '' }
      ]
    }
  }
}
</script>
