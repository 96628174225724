<template>
  <v-app :style='[cssProps]'>
    <default-layout v-if="$route.meta.layout === 'default'"
                    :maxWidth="$route.meta.maxWidth"
                    :extraColumn="$route.meta.extraColumn"
                    :search="$route.meta.search">
      <router-view :key="$route.fullPath"/>
    </default-layout>
    <auth-layout v-if="$route.meta.layout === 'auth'">
      <router-view :key="$route.fullPath"/>
    </auth-layout>
    <error-layout v-if="$route.meta.layout === 'error'">
      <router-view :key="$route.fullPath"/>
    </error-layout>
    <AppUpgraderDesktop v-if='!isNative' />
    <AppUpgrader v-if='isNative' />
  </v-app>
</template>

<script>
import DefaultLayout from "./layouts/default-layout";
import AuthLayout from "./layouts/auth-layout";
import ErrorLayout from "./layouts/error-layout";
import SmartBanner from 'smartbanner.js/src/smartbanner.js';
import {mapActions, mapGetters} from "vuex";
import safeArea from '@/mixins/safe-area';
import AppUpgrader from '@/components/app-upgrader'
import AppUpgraderDesktop from '@/components/app-upgrader-desktop'
import { isNativePlatform } from '@/helpers/global'

export default {
  mixins: [safeArea],
  metaInfo() {
    return {
      title: this.title,
      titleTemplate: `%s | Prezentmarzeń`,
      meta: [{name: 'description', content: 'description'}]
    }
  },

  components: {
    AppUpgraderDesktop,
    AppUpgrader,
    DefaultLayout,
    AuthLayout,
    ErrorLayout
  },

  watch: {
    '$route'(to) {
      this.title = to.meta.title
      this.clearSearchBar();
      window.scrollTo({top: 0, behavior: 'smooth'});
      this.$gtag.query('consent', 'default', {
        supplier: this.supplier,
        version: process.env.VUE_APP_VERSION,
        app: 'web'
      });
    },
    showErrorMessage(value) {
      if (!value) {
        return
      }

      const message = this.message ? this.message : 'Coś poszło nie tak... Nie można odczytać danych z serwera.';
      this.$toast.open({
        message, type: 'error',
        duration: 10000
      })
    }
  },

  computed: {
    ...mapGetters({
      supplier: 'supplier/supplier',
      showErrorMessage: 'app/showErrorMessage',
      message: 'app/message'
    }),

    isNative(){
      return isNativePlatform()
    },

    cssProps() {

      if (this.$vuetify.theme.isDark) {
        const {primary} = this.$vuetify.theme.themes.dark;

        return {
          '--primary-color': primary
        }
      } else {
        const {primary} = this.$vuetify.theme.themes.light;

        return {
          '--primary-color': primary
        }
      }
    }
  },

  methods: {
    ...mapActions({
      clearSearchBar: 'search/clear'
    }),

    getSystemTheme() {
      return window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
    }
  },

  data() {
    return {
      title: "Rezerwacja",
      authRoutes: ["login", "reset-password", "forget-password"]
    };
  },

  async mounted() {
    const { $helper } = this;
    const theme = localStorage.getItem("user-theme");

    if (theme) {
      this.$vuetify.theme.dark = theme === 'dark-theme' ? true : false;
    } else {
      this.$vuetify.theme.dark = this.getSystemTheme();
    }

    const isMobile = $helper.isAndroid() || $helper.isIos();

    if (isMobile && !$helper.isInstalled() && !isNativePlatform()) {
      let smartBanner = new SmartBanner();
      smartBanner.publish();
    }
  }
};
</script>

<!-- This should generally be the only global CSS in the app. -->
<style lang='scss'>
.v-toast {
  padding-left: 2em !important;
  padding-right: 2em !important;
  padding-top: calc(60px + var(--safe-area-top)) !important;
  padding-bottom: min(2em, calc(var(--safe-area-bottom))) !important;
}

.v-toast__text {
  font-family: "Roboto", sans-serif !important;
}

.theme--dark.v-application .background {
  border-color: rgba(255, 255, 255, 0.12) !important;
}

.theme--dark.v-application {
  background-color: var(--v-background-base, #0f0f0f) !important;
}

.theme--light.v-application {
  background-color: var(--v-background-base, #EEEEEE) !important;
}
</style>
