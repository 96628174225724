<template>
  <div>
    <v-system-bar :height='60 + safeArea.top' color='emotivo' dense dark app flat>
      <div class='d-flex align-center justify-space-between w-100 mobile-padding-top' fluid>
        <div class='d-flex align-center'>
          <v-btn v-if='$vuetify.breakpoint.width < 1264' @click='toggleDrawer' icon>
            <v-icon>mdi-menu</v-icon>
          </v-btn>
          <img src='@/assets/emotivo_logo.svg' alt='logo' width='169' height='37' />
        </div>
        <v-responsive max-width='360'>
          <v-text-field
            v-if='$vuetify.breakpoint.width > 500 && search'
            v-model='searchValue'
            class=''
            dense
            flat
            hide-details
            rounded
            solo-inverted
            label='Szukaj...'
            placeholder='Szukaj...'
            :append-icon='getSearchIcon'
            @click:append='clear'
          >
            <template v-slot:append>
              <v-progress-circular v-if='isFetchingResults'
                                   indeterminate
                                   color='primary'
                                   size='20'
              />
            </template>
          </v-text-field>
        </v-responsive>
        <div class='d-flex justify-end'>
          <DarkModeSwitch />
        </div>
      </div>
    </v-system-bar>
    <DrawerLayout v-if='drawer && $vuetify.breakpoint.width < 1264' :links='links' :mobile='drawer' :supplier='supplier'
                  :closeDrawer='closeDrawer' />
    <DrawerLayout v-else :links='links' :mobile='false' :supplier='supplier' />

    <v-main>
      <AppTabs v-if='$route.meta.showTabs' />
      <v-container v-if='$vuetify.breakpoint.width > 425 || showMobile || isNativePlatform' class='pb-0' fluid>
        <v-row v-if='!extraColumn' :class="{'mx-5':$vuetify.breakpoint.width>768}">
          <v-col>
            <BreadCrumb />
            <v-sheet outlined color='background' class='py-2' style='margin: 0 auto;' :max-width='maxWidth'
                     rounded='lg'>
              <slot />
            </v-sheet>
          </v-col>
        </v-row>
        <slot v-else />
      </v-container>
      <v-container class='pb-0' v-else fluid>
        <v-sheet rounded='lg' class='pa-6'>
          <h3>Aplikacja nie jest przeznaczona na to urządzenie!</h3>
          <div>Uruchom aplikację na komputerze lub tablecie. Możesz też pobrać naszą aplikację mobilną ze sklepu google
            play /
            app store.
          </div>
          <a @click='showMobileVersion'>Chcę używać wersji mobilnej w przeglądarce.</a>
          <AdvertBanner />
        </v-sheet>
      </v-container>
      <div class='my-5 d-flex justify-center'>
        <small class='grey--text'>Używasz aplikacji w wersji {{ appVersion }}</small>
      </div>
 </v-main>
  </div>
</template>

<script>
import { App } from '@capacitor/app'
import { mapGetters, mapMutations } from 'vuex'
import DrawerLayout from '@/components/layout/drawer-layout'
import AdvertBanner from '@/components/advert-banner'
import { version } from '../../package'
import BreadCrumb from '@/components/bread-crumb'
import DarkModeSwitch from '@/components/dark-mode-switch'
import { Capacitor } from '@capacitor/core'
import AppTabs from '@/components/report/app-tabs'

export default {
  components: {
    BreadCrumb,
    AdvertBanner,
    DrawerLayout,
    DarkModeSwitch,
    AppTabs
  },
  props: {
    maxWidth: {
      type: String,
      default: ''
    },
    extraColumn: {
      type: Boolean,
      default: false
    },
    search: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      showModal: false,
      appVersion: '0.0.0',
      displayMobile: false,
      extraMargin: false,
      drawer: false,
      menu: ['Home'],
      links: [
        { title: 'Sprawdź voucher', path: '/', icon: 'mdi-qrcode', role: ['user'] },
        { title: 'W rezerwacji', path: '/voucher-reservation', icon: 'mdi-account-group', role: ['user'] },
        { title: 'Zrealizowane', path: '/realized-vouchers', icon: 'mdi-checkbox-multiple-marked', role: ['user'] },
        { title: 'Kalendarz', path: '/calendar', icon: 'mdi-calendar', role: ['user'] },
        { title: 'Rozliczone', path: '/voucher-accounted', icon: 'mdi-cash-multiple', role: ['user'] },
        { title: 'Raporty', path: '/reports', icon: 'mdi-chart-box', role: ['user'] },
        { title: 'FAQ', path: '/faq', icon: 'mdi-frequently-asked-questions', role: ['user'] },
        { title: 'Wyloguj się', path: '/logout', icon: 'mdi-login-variant', role: ['user'] },
        { title: 'Oznaczenia Partnerów', path: '/oznaczenia-partnerow', icon: 'mdi-information', role: ['user'] }
      ]

    }
  },

  watch: {
    $route() {
      this.drawer = false
    }
  },

  computed: {
    ...mapGetters({
      getSearchValue: 'search/searchValue',
      isFetchingResults: 'search/isFetchingResults',
      supplier: 'supplier/supplier',
      token: 'auth/getToken',
      safeArea: 'app/safeArea'
    }),

    isNativePlatform() {
      return Capacitor.isNativePlatform()
    },

    superAdminName() {
      return this.$helper.getConstant('SUPER_ADMIN_COOKIE_NAME')
    },

    getSearchIcon() {
      const { searchValue } = this

      if (searchValue.length > 0) {
        return 'mdi-close'
      } else {
        return 'mdi-magnify'
      }
    },

    searchValue: {
      get() {
        return this.getSearchValue
      },

      set(newValue) {
        this.setSearchValue(newValue)
      }
    },

    showMobile: {
      get() {
        if (this.$helper.isInstalled()) {
          return true
        } else {
          return this.displayMobile
        }
      },

      set(value) {
        this.displayMobile = value
      }
    }
  },

  methods: {
    ...mapMutations({
      setSearchValue: 'search/SET_VALUE'
    }),

    getAppVersion() {
      if (this.$helper.isNativePlatform()) {
        return App.getInfo().then((info) => {
          return 'm' + info.version + '(' + version + ')'
        })
      }

      return version
    },

    closeModal() {
      this.showModal = false
    },

    showMobileVersion() {
      this.showMobile = true
    },

    clear() {
      this.setSearchValue('')
    },

    toggleDrawer() {
      this.drawer = !this.drawer
    },

    closeDrawer() {
      this.drawer = false
    }
  },

  async mounted() {
    this.appVersion = await this.getAppVersion()
  }
}
</script>
<style lang='scss' scope>
.mobile-padding-top {
  padding-top: var(--safe-area-top) !important;
}

.relative {
  position: relative;
}

.container {
  &--fluid {
    max-width: 1800px;
  }
}

.v-dialog {
  border-radius: 20px !important;
}

.w-100 {
  width: 100%;
}

.filter-wrapper {
  display: grid;
  align-items: center;
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 0;
}

.date-picker { grid-area: 1 / 1 / 2 / 4; }
.show-all { grid-area: 2 / 1 / 3 / 4; }
.select-all { grid-area: 3 / 1 / 3 / 4; }

@media (max-width: 1023px) {
  .date-picker > .swith { display: none }
}

@media (min-width: 768px) {
  .filter-wrapper {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  .date-picker { grid-area: 1 / 1 / 2 / 3; }
  .show-all { grid-area: 1 / 3 / 2 / 4; }
  .select-all { grid-area: 2 / 1 / 3 / 2; }
}
</style>
