module.exports = {
    env: process.env.NODE_ENV ?? 'development',
    apiBaseUrl: process.env.VUE_APP_API_BASE_URL + '/modules/voucherreservationapp/',
    as_api: process.env.VUE_APP_AS_API + '/api',
    lm_api: process.env.VUE_APP_API_BASE_URL + '/lm_api/supplier-api',
    super_admin: process.env.VUE_APP_API_BASE_URL + '/lm_api/ff3b4b0290663535ebb9922bbb047bd0-api',
    baseUrl: process.env.VUE_APP_FRONTEND_URL,
    cdnBaseUrl: process.env.VUE_APP_CDN_URL,
    publicPath: process.env.VUE_APP_PUBLIC_PATH ?? '/',
    googleAnalyticsDefaultId: 'G-T9FX3WN62D',
    googleAnalyticsId: '',
    appVersion: process.env.VUE_APP_VERSION ?? '1.0',
    hotjar: process.env.VUE_APP_HOTJAR ?? '000000'
};
