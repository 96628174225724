<template>
  <v-tabs class='activeTabs' :style="{ top: `calc(60px + ${safeArea.top}px)` }" fixed-tabs dark>
    <v-tab to='/reports'>Raporty</v-tab>
    <v-tab to='/reports/archive'>Raporty Archiwalne</v-tab>
  </v-tabs>
</template>
<script>

import { mapGetters } from 'vuex'

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },

    itemsCount: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {}
  },

  computed: {
    ...mapGetters({
      safeArea: 'app/safeArea'
    }),
  }
}
</script>
<style lang='scss' scoped>
.activeTabs {
  position: sticky;
  z-index: 6;
}
</style>
