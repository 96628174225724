const {fetchData} = require('@/helpers/featch');

export const state = {
    vouchers: [],
    pagesCount: 0
};

export const getters = {
    vouchers: (state) => state.vouchers,
    pagesCount: (state) => state.pagesCount,
};

export const mutations = {
    SET_VOUCHERS(state, data) {
        state.vouchers = data;
    },

    SET_PAGES_COUNT(state, data) {
        state.pagesCount = data;
    }
};

export const actions = {
    async getSettledVouchers({commit, dispatch}, payload) {
        try {
            dispatch("app/updateIsBusy", true, { root: true });
            const params = new URLSearchParams(payload).toString();
            const {data} = await fetchData.post("/GetSettledVouchers", {}, params);
            if (data) {
                const onPage = payload.on_page ? payload.on_page : 100;
                const pagesCount = Math.round(data.itemsCount / onPage);
                commit('SET_VOUCHERS', data.items);
                commit('SET_PAGES_COUNT', pagesCount);

                return data.items;
            }
        } catch (e) {
            console.error(e);
        }finally {
            dispatch("app/updateIsBusy", false, { root: true });
        }
    }
};
