// import { normalizeJsonHelper } from '@/normalizers/index'


function normalizeReportList(inputJson) {
  return inputJson.map(item => {
    return {
      'id': item['id'],
      'report_number': item['report_number'],
      'report_type': item['report_type'],
      'report_status_name': item['state'],
      'supplier_name': item['supplier_name'],
      'email': item['email'],
      'nip': item['nip'],
      'creation_date': item['generate_date'],
      'confirm_date': item['accept_date'],
      'acceptant_user': item['acceptant_user'],
      'number_invoice_for_report': item['invoice_number']
    }
  })
}

function normalizeReportListJSON(json) {
  try {
    const { result_info } = json
    return {
      items: normalizeReportList(json['result']),
      'page': result_info['page'],
      'pageSize': result_info['per_page'],
      'totalPages': Math.ceil(result_info['total_count'] / result_info['per_page']),
      'itemsCount': result_info['count'],
      'code': 1000
    }
  } catch (e) {
    return {
      items: [],
      'page': 1,
      'pageSize': 20,
      'totalPages': 1,
      'itemsCount': 0,
      'code': 1000
    }
  }
}

export { normalizeReportListJSON }
