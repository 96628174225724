import store from '@/store'
import { isProduction } from '@/helpers/global'
import { now } from 'moment'

const routes = [
  {
    path: '/oznaczenia-partnerow',
    meta: { authRequired: true },
    beforeEnter() {
      window.open(
        'https://prezentmarzen.com/oznaczenia-partnerow/?' + now(),
        '_blank'
      )
    }
  },
  {
    path: '/',
    name: 'dashboard',
    component: () => import('../views/home-page'),
    meta: { title: 'Sprawdź voucher', layout: 'default', maxWidth: '1080', extraColumn: false, authRequired: true },
    children: []
  },
  {
    path: '/voucher-reservation',
    name: 'voucher-reservation',
    component: () => import('../views/voucher-reservation'),
    meta: { title: 'W rezerwacji', layout: 'default', authRequired: true, search: true, status: 'in_cart' },
    children: []
  },

  {
    path: '/reports',
    name: 'reports',
    component: () => import('../views/reports-page'),
    meta: {
      title: 'Raporty',
      layout: 'default',
      authRequired: true,
      search: true,
      async beforeResolve(routeTo, routeFrom, next) {
        const { has_new_settlements } = await store.getters['supplier/supplier']
        if (has_new_settlements === '1') {
          routeTo.meta.showTabs = true
          routeTo.meta.appService = true
        } else {
          routeTo.meta.appService = false
        }
        next()
      }
    }
  },

  {
    path: '/reports/archive',
    name: 'reports-archive',
    component: () => import('../views/reports-page'),
    meta: {
      title: 'Raporty Archiwalne', layout: 'default', authRequired: true, search: true,
      async beforeResolve(routeTo, routeFrom, next) {
        const { has_new_settlements } = await store.getters['supplier/supplier']
        if (has_new_settlements === '1') {
          routeTo.meta.showTabs = true
          routeTo.meta.appService = false
          next()
        } else {
          next({ name: 'error' })
        }
      }
    }
  },

  {
    path: '/report/:symbol/:year/:month/:number/:id',
    name: 'appservice-report',
    component: () => import('../views/report-page'),
    meta: { title: 'Raport', layout: 'default', authRequired: true, appService: true }
  },

  {
    path: '/report/:symbol/:year/:month/:number',
    name: 'report',
    component: () => import('../views/report-page'),
    meta: { title: 'Raport', layout: 'default', authRequired: true, appService: false }
  },

  {
    path: '/realized-vouchers',
    name: 'realized-vouchers',
    component: () => import('../views/realized-vouchers'),
    meta: {
      title: 'Zrealizowane', layout: 'default', authRequired: true, status: 'terminated',
      async beforeResolve(routeTo, routeFrom, next) {
        const { has_new_settlements } = await store.getters['supplier/supplier']
        if (has_new_settlements === '1') {
          routeTo.meta.appService = true
          routeTo.meta.search = false
        } else {
          routeTo.meta.appService = false
          routeTo.meta.search = true
        }

        next()
      }
    },
    children: []
  },

  {
    path: '/voucher-accounted',
    name: 'voucher-accounted',
    component: () => import('../views/voucher-accounted'),
    meta: { title: 'Rozliczone', layout: 'default', authRequired: true, search: true, status: 'used' },
    children: []
  },

  {
    path: '/calendar',
    name: 'calendar',
    component: () => import('../views/calendar-page'),
    meta: { title: 'Kalendarz', layout: 'default', authRequired: true },
    children: []
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('../views/faq-page'),
    meta: { title: 'FAQ', layout: 'default', maxWidth: '1080', authRequired: true },
    children: []
  },
  {
    path: '/info',
    name: 'info',
    component: () => import('../views/info-page'),
    meta: { title: 'Info', layout: 'default', maxWidth: '1080', authRequired: true },
    children: []
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/auth/login-page'),
    meta: { title: 'Logowanie', layout: 'auth', authRequired: false },
    children: []
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('../views/auth/reset-password'),
    meta: { title: 'Zmiana hasła', layout: 'auth', authRequired: false },
    children: []
  },
  {
    path: '/camera/:device',
    name: 'camera-faq',
    component: () => import('../views/camera-faq'),
    meta: { title: 'Kamera', layout: 'default', authRequired: true },
    children: []
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        const response = await store.dispatch('auth/validate')
        const isLoggedIn = response && await store.getters['auth/isLoggedIn']
        if (isLoggedIn) {
          await store.dispatch('auth/logout')
          return next({ name: 'login' })
        } else {
          return next({ name: 'dashboard' })
        }
      }
    }
  },
  {
    path: '/super-admin',
    name: 'login-super-admin',
    component: () => import('../views/auth/super-admin'),
    meta: {
      title: 'Super Admin Login', layout: 'auth', authRequired: false,
      async beforeResolve(routeTo, routeFrom, next) {
        if (!isProduction()) {
          return next()
        } else {
          return next({ name: 'error' })
        }
      }
    },
    children: [
      {
        path: ':token',
        name: 'super-admin-token',
        component: () => import('../views/auth/super-admin'),
        meta: {
          title: 'Super Admin Token', layout: 'auth', authRequired: false
        }
      }
    ]
  },
  {
    path: '/*',
    name: 'error',
    component: () => import('../views/page-not-found'),
    meta: {
      title: 'Error',
      layout: 'error',
      authRequired: false
    }
  }
]

export default routes
