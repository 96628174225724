<template>
  <div>
    <v-system-bar :height='60 + safeArea.top' color='emotivo' id='emotivo-navbar' dense dark app flat>
      <v-container id='emotv-logo' class='d-flex justify-center py-0' fluid>
        <img src='@/assets/emotivo_logo.svg' alt='logo' width='169' height='37' />
      </v-container>
    </v-system-bar>
    <v-main class="d-flex align-center" :class="{'container-height': !isNative}">
      <v-container class='fill-height justify-center main-container d-flex' max-width='1080' fluid>
        <slot />
      </v-container>
    </v-main>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { isNativePlatform } from '@/helpers/global'

export default {
  components: {},
  props: {},

  data() {
    return {
      pendingUpdate: false
    }
  },

  watch: {},
  computed: {
    ...mapGetters({
      safeArea: 'app/safeArea'
    }),
    isNative(){
      return isNativePlatform();
    }
  }
}
</script>
<style lang='scss' scope>
.container-height{
  height: calc(100vh - 60px) !important;
}
#toolbar-wrap {
  position: sticky;
  top: 0px;
  height: 60px;
  width: 100%;
}

#emotv-logo {
  padding-top: var(--safe-area-top) !important;
}

.main-container {
  min-height: 600px;
  height: calc(100vh - 60px - var(--safe-area-top));
}

.heading-primary {
  font-size: 3rem;
}

.span-purple {
  color: var(--primary-color);
}

.signup-container,
.signup-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.signup-container {
  padding: 40px;
  max-width: 500px;
  align-items: flex-start;
  justify-content: flex-start;
}

.signup-form {
  width: 100%;
}

.text-mute {
  color: #aaa;

  a {
    text-decoration: none;
  }
}

.custom-btn {
  color: #222;
  background: #fff;
  border: solid 1px #eee;
  padding: 10px;
  text-decoration: none;

  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 2px #00000020;
}


.login-wrapper {
  max-width: 45rem;
  width: 100%;
}

.line-breaker .line {
  width: 50%;
  height: 1px;
  background: #eee;
}

.line-breaker {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ccc;
}

.line-breaker span:nth-child(2) {
  margin: 0 2rem;
}

</style>

