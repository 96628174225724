import { CONSTANTS } from '@/constants'

export const MESSAGES = {
  1001: CONSTANTS.RAPORT_DELETED_CODE_MESSAGE,
  2001: CONSTANTS.OBJECT_NOT_FOUND_MESSAGE,
  2002: CONSTANTS.INVALID_CREDENTIALS_CODE_MESSAGE,
  2003: CONSTANTS.INVALID_TOKEN_MESSAGE,
  2004: CONSTANTS.TOKEN_EXPIRED_MESSAGE,
  3001: CONSTANTS.OPERATION_FAILED,
  // 3002: CONSTANTS.IN_RESERVATION_MESSAGE,
  3003: CONSTANTS.TERMINATED_MESSAGE,
  3004: CONSTANTS.USED_MESSAGE,
  3005: CONSTANTS.VOUCHER_BLOCKED_MESSAGE,
  3006: CONSTANTS.VOUCHER_EXPIRED_MESSAGE,
  3007: CONSTANTS.VOUCHER_TYPE_MESSAGE,
  3008: CONSTANTS.GIFTCARD_MESSAGE,
  3009: CONSTANTS.VOUCHER_BOX_MESSAGE,
  3010: CONSTANTS.VOUCHER_BELONGS_TO_ANOTHER_CONTRACTOR_MESSAGE,
  4001: CONSTANTS.COMMUNICATION_ERROR_MESSAGE,
  giftcard: CONSTANTS.GIFTCARD_MESSAGE,
  in_order: CONSTANTS.IN_ORDER_MESSAGE,
  terminated: CONSTANTS.TERMINATED_MESSAGE,
  realized: CONSTANTS.REALIZED_MESSAGE,
  used: CONSTANTS.USED_MESSAGE,
  blocked: CONSTANTS.NOT_ACTIVE_MESSAGE,
  expired: CONSTANTS.EXPIRED_MESSAGE,
}

export const STATUSES = {
  2001: CONSTANTS.NOT_EXISTS,
  3002: CONSTANTS.IN_RESERVATION,
  3003: CONSTANTS.TERMINATED,
  3004: CONSTANTS.USED,
  3005: CONSTANTS.BLOCKED,
  3006: CONSTANTS.EXPIRED,
  3007: CONSTANTS.NOT_ACTIVE,
  3008: CONSTANTS.GIFTCARD,
  3009: CONSTANTS.BOX,
  3010: CONSTANTS.NOT_EXISTS,
  giftcard: CONSTANTS.GIFTCARD,
  in_reservation: CONSTANTS.IN_RESERVATION,
  in_cart: CONSTANTS.IN_CART,
  in_order: CONSTANTS.IN_ORDER,
  active: CONSTANTS.ACTIVE,
  terminated: CONSTANTS.TERMINATED,
  used: CONSTANTS.USED,
  blocked: CONSTANTS.BLOCKED,
  expired: CONSTANTS.EXPIRED,
  realized: CONSTANTS.TERMINATED
}
