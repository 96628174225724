const {fetchData} = require('@/helpers/featch');
import * as Sentry from "@sentry/browser";

export const state = {
    supplier: {},
    provisionSupplier: false,
};

export const getters = {
    supplier: (state) => state.supplier,
    provisionSupplier: (state) => state.provisionSupplier,
};

export const mutations = {
    SET_SUPPLIER(state, data) {
        state.supplier = data;
    },

    SET_PROVISION_SUPPLIER(state, data) {
        state.provisionSupplier = data['provision_supplier'];
    }
};

export const actions = {
    async getSupplier({commit, dispatch}) {
        try {
            dispatch("app/updateIsBusy", true, { root: true });
            const {data} = await fetchData.post("/GetSupplier");
            if(data) {
                commit('SET_PROVISION_SUPPLIER', data);
                commit('SET_SUPPLIER', data);
                Sentry.setUser(data);

                return data;
            }
        } catch (e) {
            console.error(e);
        }finally {
            dispatch("app/updateIsBusy", false, { root: true });
        }
    },
};
