import {CONSTANTS} from '@/constants'

export const ALERT_MESSAGES = {
  1000: CONSTANTS.OPERATION_SUCCEEDED,
  1001: CONSTANTS.RAPORT_DELETED_CODE_MESSAGE,
  2000: CONSTANTS.OPERATION_FAILED,
  2001: CONSTANTS.NOT_EXISTS,
  2002: CONSTANTS.INVALID_CREDENTIALS_CODE_MESSAGE,
  2003: CONSTANTS.INVALID_TOKEN_MESSAGE,
  2004: CONSTANTS.TOKEN_EXPIRED_MESSAGE,
  3000: 'Nie mogliśmy wykonać tej akcji. Błąd w logice biznesowej.',
  3001: CONSTANTS.INVALID_STATUS_MESSAGE,
  3002: 'Nie można wykonać akcji dla vouchera o statusie w rezerwacji.',
  3003: 'Nie można wykonać akcji dla vouchera o statusie zrealizowany.',
  3004: 'Nie można wykonać akcji dla vouchera o statusie rozliczony.',
  3005: 'Nie można wykonać akcji dla vouchera o statusie zablokowany.',
  3006: 'Nie można wykonać akcji dla vouchera o statusie przeterminowany.',
  3007: 'Nie można wykonać akcji dla vouchera o tym typie.',
  3008: CONSTANTS.INVALID_STATUS_MESSAGE,
  4000: CONSTANTS.INTERNAL_ERROR_MESSAGE,
  4001: CONSTANTS.INTERNAL_SERVER_ERROR
}
