import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';
import routes from './routes';
import VueGtag from 'vue-gtag';
import config from '../config';
import {isProduction} from "@/helpers/global";

Vue.use(VueRouter);

const router = new VueRouter({
    routes,
    base: config.publicPath,
    mode: 'history',
    linkActiveClass: 'active'
});


Vue.use(
    VueGtag,
    {
        config: {
            id: config.googleAnalyticsDefaultId, params: {
                anonymize_ip: true
            }
        },
        includes: [{id: config.googleAnalyticsId}],
        appName: 'Wykonawca',
        pageTrackerScreenviewEnabled: true,
        pageTrackerTemplate(to) {
            return {
                page_title: to.path,
                page_path: to.path
            };
        }
    },
    router
);


const exclude = ['login', 'reset-password', 'error'];
const redirectIfLoggedIn = ['login', 'reset-password'];
let refreshTokenExecuted = false;

router.beforeEach(async (routeTo, routeFrom, next) => {
    if(routeTo.query.token && !isProduction()) {
        return next(`/super-admin/${routeTo.query.token}`);
    }

    const authRequired = routeTo.matched.some(route => route.meta.authRequired);
    const isLoggedIn = await store.getters['auth/isLoggedIn'];

    if (!exclude.includes(routeTo.name) && isLoggedIn) {
        await store.dispatch('auth/validate');
    }

    if (redirectIfLoggedIn.includes(routeTo.name) && isLoggedIn) {
        return next('/');
    }

    if (!authRequired) return next();

    if (isLoggedIn) {
        if(localStorage.getItem('stayLoggedIn') === '1' && !redirectIfLoggedIn.includes(routeFrom.name)) {
            !refreshTokenExecuted ? await store.dispatch('auth/refreshToken') : null;
            refreshTokenExecuted = true;
        }
        await store.dispatch('supplier/getSupplier');
        return next();
    } else {
        return next({
            name: 'login',
            query: {redirectFrom: routeTo.path}
        });
    }
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
    // Create a `beforeResolve` hook, which fires whenever
    // `beforeRouteEnter` and `beforeRouteUpdate` would. This
    // allows us to ensure data is fetched even when params change,
    // but the resolved route does not. We put it in `meta` to
    // indicate that it's a hook we created, rather than part of
    // Vue Router (yet?).

    try {
        // For each matched route...
        for (const route of routeTo.matched) {
            await new Promise((resolve, reject) => {
                // If a `beforeResolve` hook is defined, call it with
                // the same arguments as the `beforeEnter` hook.
                if (route.meta && route.meta.beforeResolve) {
                    route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
                        // If the user chose to redirect...

                        if (args.length) {
                            // Complete the redirect.
                            next(...args);
                            reject(new Error('Redirected'));
                        } else {
                            resolve();
                        }
                    });
                } else {
                    // Otherwise, continue resolving the route.
                    resolve();
                }
            });
        }
        // If a `beforeResolve` hook chose to redirect, just return.
    } catch (error) {
        return;
    }

    // If we reach this point, continue resolving the route.
    next();
});

export default router;
