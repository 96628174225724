import Vue from 'vue';
import Hotjar from 'vue-hotjar';
import config from '../config';


if (process.env.NODE_ENV === 'production') {
    Vue.use(Hotjar, {
        id: config.hotjar, // Hotjar Site ID
        isProduction: true,
    })
}
