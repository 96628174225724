import {fetchData} from "@/helpers/featch";

export const state = {
    searchValue: '',
    totalPages: 1,
    isFetchingResults: false,
};

export const getters = {
    searchValue: (state) => state.searchValue,
    totalPages: (state) => state.totalPages,
    isFetchingResults: (state) => state.isFetchingResults,
};

export const mutations = {
    SET_VALUE(state, payload) {
        state.searchValue = payload;
    },

    SET_TOTAL_PAGES(state, value) {
        state.totalPages = value;
    },

    SET_FETCHING_VALUE(state, value) {
        state.isFetchingResults = value;
    }
};

export const actions = {
    async clear({commit}) {
        commit("SET_VALUE", "")
    },

    async search({commit, dispatch}, payload) {
        try {
            dispatch("app/updateIsBusy", true, { root: true });
            commit("SET_FETCHING_VALUE", true);
            const {query} = payload;
            const body = JSON.stringify({query});
            const params = new URLSearchParams(payload.params).toString();
            const {data} = await fetchData.post("/Search", body, params);
            commit("SET_FETCHING_VALUE", false);

            if (data) {
                commit('SET_TOTAL_PAGES', data.totalPages);

                return data.items;
            } else {
                return []
            }
        } catch (e) {
            commit("SET_LOADING", false);
            console.error(e);
        }finally {
            dispatch("app/updateIsBusy", false, { root: true });
        }
    },
};
