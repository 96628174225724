export const state = {
    message: '',
    showErrorMessage: false,
    isBusy: false,
    safeArea: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    }
};

export const getters = {
    message: (store) => store.message,
    showErrorMessage: (store) => store.showErrorMessage,
    safeArea: (store) => store.safeArea,
    isBusy: (store) => store.isBusy,
};

export const mutations = {
    SET_SAFE_AREA(state, value) {
        state.safeArea = value;
    },
    SET_ERROR_MESSAGE(state, value) {
        state.message = value;
    },
    SET_SHOW_ERROR(state, value){
        state.showErrorMessage = value;
    },
    IS_BUSY(state, payload) {
        state.isBusy = payload;
    },
};

export const actions = {
    /* eslint-disable */
    async showErrorMessage({commit}, {message}) {
        commit("SET_ERROR_MESSAGE", message);
        commit("SET_SHOW_ERROR", true);
        setTimeout(()=>{
            commit("SET_SHOW_ERROR", false);
        },1000);
    },

    updateIsBusy({ commit }, payload) {
        commit('IS_BUSY', payload);
    },
};
