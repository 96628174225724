function normalizeReportVoucherList(inputJson) {
  return inputJson.map(item => {
    return {
      'id_voucher': item.id,
      'sell_pipe': item.sell_pipe,
      'voucher_number': item.hash,
      'product_name': item.product_name,
      'product_price_tax_incl': `${item.base_price} zł`,
      'provision': `${item.provision}%`,
      'tax_rate': `${item.vat_rate}%`,
      'amount_tax_excl': `${item.amount_tax_excl} zł`,
      'amount_tax_rate': `${item.amount_tax_rate} zł`,
      'amount_tax_incl': `${item.amount_tax_incl} zł`,
      'status': item.status
    }
  });
}

function splitReport(inputJson) {
  const reportSummary = []
  const reportCorrection = []
  for (const item of inputJson['report_summaries']) {
    const summaryItem = {
      'tax_rate': `${item.tax_rate}%`,
      'amount_tax_excl': `${item['original_tax_excl']} zł`,
      'amount_tax_rate': `${item['original_tax_rate']} zł`,
      'amount_tax_incl': `${item['original_tax_incl']} zł`
    }

    reportSummary.push(summaryItem)

    if (
      parseFloat(item.change_tax_excl) !== 0 ||
      parseFloat(item.change_tax_rate) !== 0 ||
      parseFloat(item.change_tax_incl) !== 0
    ) {
      const correctionItem = {
        'tax_rate': `${item.tax_rate}%`,
        'change_tax_excl': `${item['change_tax_excl']} zł`,
        'change_tax_rate': `${item['change_tax_rate']} zł`,
        'change_tax_incl': `${item['change_tax_incl']} zł`
      }
      reportCorrection.push(correctionItem)
    }
  }

  return { reportSummary, reportCorrection }
}


function normalizeReportJSON(json) {
  try {
    const { reportSummary, reportCorrection } = splitReport(json)
    return {
      reportStatus: {
        id: json.id,
        number: json['report_number'],
        report_type: json.report_type,
        state: json.state,
        supplier_name: json.supplier_name,
        supplier_email: json.email,
        supplier_nip: json.nip,
        creation_date: json.generate_date,
        confirmation_date: json.accept_date,
        acceptant_user: json.acceptant_user,
        invoice_number: json.invoice_number,
        invoice_note_link: json.invoice_note_link,
        supplier_message: json.comment,
        correct_comment: json.correct_comment,
        org_amount_tax_excl: `${json.summary_tax_excl} zł`,
        org_amount_tax_rate: `${json.summary_tax_rate} zł`,
        org_amount_tax_incl: `${json.summary_tax_incl} zł`
      },
      reportVoucherList: normalizeReportVoucherList(json['report_positions']),
      reportSummary, reportCorrection
    }
  } catch (e) {
    return {
      reportStatus: {},
      reportVoucherList: [],
      reportSummary: [],
      reportCorrection: []
    }
  }
}

export { normalizeReportJSON }
