import axios from "axios";
import config from "../config/index";

axios.defaults.baseURL =  config.apiBaseUrl;
axios.defaults.headers.post['Content-Type'] = 'application/json, text/plain, */*';

if (localStorage.token) {
    axios.defaults.headers.common["token"] = JSON.parse(localStorage.token);
}

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        const err = error.response.request.responseURL;
        const fromUrl = err.split("/").pop();
        const nonRedirectUrls = ["login", "reset-password"];

        if (error.response.status === 401 && !nonRedirectUrls.includes(fromUrl)) {
            window.location.href = "/login";
            localStorage.clear();
        }

        throw error;
    }
);

export default axios;
