import Vue from 'vue';
import * as Sentry from "@sentry/vue";
import {version} from "../../package.json";
import {BrowserTracing} from "@sentry/tracing";
import router from "@/router";


// if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        release: process.env.NODE_ENV === 'production' ? ("supplier-web-app@" + version) : ("stage@" + version),
        environment: process.env.NODE_ENV,
        // Passing in `Vue` is optional, if you do not pass it `window.Vue` must be present.
        Vue: Vue,
        dsn: "https://e0d0be08aa8147a9ab0bf899d9e74260@o1428358.ingest.sentry.io/4504769599504384",

        // This enables automatic instrumentation (highly recommended), but is not
        // necessary for purely manual usage
        integrations: [new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracePropagationTargets: ["wykonawca.emotivo.pl", /^\//],
        })],
        debug: false,

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
        trackComponents: true,
        hooks: ['mount', 'update'],
    });
// }
