import moment from "moment";
import config from "../config";
import { CONSTANTS } from '@/constants';
import { Capacitor } from '@capacitor/core'
import {MODAL_MESSAGES} from '@/constants/modalMessages';
import { ALERT_MESSAGES } from '@/constants/alertMessages'

export const isNativePlatform = () => {
    return Capacitor.isNativePlatform();
}

export const truncate = (input = '', maxLength = 16) => {

    if (input.length > maxLength) {
        return input.substring(0, maxLength) + '...';
    }
    return input;
};

export const getDefaultDate = () => {
    const startDate = moment().subtract(1, 'months').format('YYYY-MM-DD');
    const endDate = moment().format("YYYY-MM-DD");

    return {startDate, endDate};
}

export const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
}

export const isAndroid = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /android/.test(userAgent) && (window.innerWidth <= 768);
}

export const isInStandaloneMode = () => window.navigator.standalone === true;

export const isInstalled = ()=> {
    // For iOS
    if (window.navigator.standalone) {
        return true
    }

    // For Android
    if (window.matchMedia('(display-mode: standalone)').matches) return true

    // If neither is true, it's not installed
    return false
}

export const tokenIat = (token) => {
    try {
        return (JSON.parse(atob(token.split('.')[1]))).iat;
    } catch (e) {
        console.error(e);
    }
}

export const tokenExp = (token) => {
    try {
        return (JSON.parse(atob(token.split('.')[1]))).exp;
    } catch (e) {
        console.error(e);
    }
}

export const tokenExpIn = (token) => {
    const timeISO = moment.unix(tokenExp(token));
    return timeISO.diff(moment(), 'minutes');
}

export const isURL = (url) => {
    const regex = /^(http|https)/;
    if (regex.test(String(url).toLowerCase())) {
        return true;
    }

    return false;
}

export const getImageURL = (image, width = null, height = null, format = 'avif') => {
    let size = '';
    if (width !== null) {
        size = `,width=${width}`;
    }

    if (height !== null) {
        size = `,height=${height}`;
    }

    if (isURL(image)) {
        return image;
    }

    return `${config.cdnBaseUrl}/cdn-cgi/image/format=${format}${size}${image}`

}

export const toFixed = (number, digits = 2) => {
    const num = Number(number);

    return num.toFixed(digits);
}

export const getConstant = (key) =>{

    return CONSTANTS[key];
}

export const removeSpecialCharacters = (str) => {
    const pattern = /[^\w\s]/g;
    if(typeof str === "string") {
        return str.replace(pattern, '');
    }

    return str.toString().replace(pattern, '');
}

export const isPimShop = sell_pipe => {
    const shops = getConstant('SHOPS');

    return shops.includes(sell_pipe);
};

export const isProduction = () => {
    return config.env === 'production';
}

export const plural = (number, object = { one: 'voucher', few: 'vouchery', many: 'voucherów' }) =>{
    const pluralRules = new Intl.PluralRules('pl-PL');
    const pluralCategory = pluralRules.select(number);
    const pluralForm = object[pluralCategory];

    return number + ' ' + pluralForm;
}

export const getModalMessage = (name) => {
    try {
        return MODAL_MESSAGES[name];
    }catch (e){
        return {
            title: 'Coś poszło nie tak...',
            message: 'Spróbuj jeszcze raz lub skontaktuj się z Biurem Obsługi Klienta'
        }
    }
}

export const getAlertMessage = (code) => {
    try {
        if(!ALERT_MESSAGES[code]){
           throw new Error();
        }

        return ALERT_MESSAGES[code];
    }catch (e){
        return `Nie rozpoznano kodu ${code}`
    }
}

