import { normalizeReportJSON } from '@/normalizers/report'
import { appserviceFetchData } from '@/helpers/featch'
import { normalizeReportListJSON } from '@/normalizers/reports'
import { formatDateToISO } from '@/helpers/date'
import contractType from '@/helpers/contractType'

const {fetchData} = require('@/helpers/featch');

export const state = {
    comment: '',
    totalPages: 0,
    reportList: [],
    reportCorrection: [],
    reportStatus: {},
    reportSummary: [],
    reportVoucherList: []
};

export const getters = {
    comment: (state) => state.comment,
    totalPages: (state) => state.totalPages,
    reportList: (state) => state.reportList,
    reportCorrection: (state) => state.reportCorrection,
    reportStatus: (state) => state.reportStatus,
    reportSummary: (state) => state.reportSummary,
    reportVoucherList: (state) => state.reportVoucherList,
};

export const mutations = {
    SET_COMMENT(state, data){
      state.comment = data;
    },

    SET_TOTAL_PAGES(state, data) {
        state.totalPages = data;
    },

    SET_REPORT_LIST(state, data) {
        state.reportList = data;
    },

    SET_REPORT_STATUS(state, data) {
        state.reportStatus = data;
    },

    SET_REPORT_SUMMARY(state, data) {
        state.reportSummary = data;
    },

    SET_REPORT_VOUCHER_LIST(state, data) {
        state.reportVoucherList = data;
    },

    SET_REPORT_CORRECTION(state, data) {
        state.reportCorrection = data;
    },

    CLEAR_STORE(state) {
        state.reportCorrection = [];
        state.reportStatus = {};
        state.reportSummary = [];
        state.reportVoucherList = [];
    }
};

export const actions = {
    async getReport({commit, dispatch}, { payload, appService = false}) {
        try {
            dispatch("app/updateIsBusy", true, { root: true });
            const {id, reportNumber} = payload;

            if(appService){
                const {data} = await appserviceFetchData.get(`/reports/${id}`);
                if (data) {
                    const normalizedData = normalizeReportJSON(data.result);
                    commit('SET_REPORT_CORRECTION', normalizedData['reportCorrection']);
                    commit('SET_REPORT_STATUS', normalizedData['reportStatus']);
                    commit('SET_REPORT_SUMMARY', normalizedData['reportSummary']);
                    commit('SET_REPORT_VOUCHER_LIST', normalizedData['reportVoucherList']);

                return data;
                }
            }

            const body = JSON.stringify({reportNumber});
            const {data} = await fetchData.post("/GetReport", body);
            if (data) {
                commit('SET_REPORT_CORRECTION', data.reportCorrection);
                commit('SET_REPORT_STATUS', data.reportStatus);
                commit('SET_REPORT_SUMMARY', data.reportSummary);
                commit('SET_REPORT_VOUCHER_LIST', data.reportVoucherList);

                return data;
            }
        } catch (e) {
            console.error(e);
        }finally {
            dispatch("app/updateIsBusy", false, { root: true });
        }
    },

    /* eslint-disable */
    async updateComment({commit, dispatch}, { id, comment }) {
        try {
            dispatch("app/updateIsBusy", true, { root: true });
            const body = JSON.stringify({comment});
            const {data}  = await appserviceFetchData.path(`/reports/${id}`, body);

            return data;
        } catch (e) {
            console.error(e);
        }finally {
            dispatch("app/updateIsBusy", false, { root: true });
        }
    },

    /* eslint-disable */
    async sendReport({commit, dispatch}, { payload,  appService = false}) {
        try {
            dispatch("app/updateIsBusy", true, { root: true });
            const body = JSON.stringify(payload);

            if(appService){
                const {data}  = await appserviceFetchData.put(`/reports/${payload.id}/send`);
                return data;
            }

            const {data} =  await fetchData.post("/SendReport", body);
            return data;

        } catch (e) {
            console.error(e);
        }finally {
            dispatch("app/updateIsBusy", false, { root: true });
        }
    },

    async getReports({commit, dispatch, rootState}, { payload,  appService = false}) {
        try {
            dispatch("app/updateIsBusy", true, { root: true });
            payload['report_type'] = contractType(rootState.supplier.provisionSupplier)
            payload['order'] = 'desc';
            payload['order_by'] = 'generate_date';
            const params =  Object.keys(payload).length ? new URLSearchParams(payload).toString() : null;

            if(appService){
                const {data}  = await appserviceFetchData.get(`/reports`,params);
                const totalPages = data['result_info']['total_count'] / data['result_info']['per_page'];

                const normalizedJson = normalizeReportListJSON(data);
                commit('SET_REPORT_LIST', normalizedJson.items);
                commit('SET_TOTAL_PAGES', Math.ceil(totalPages));

                return normalizedJson;
            }

            const {data} = await fetchData.post("/GetReports", {}, params);
            if (data) {
                commit('SET_REPORT_LIST', data.items);
                commit('SET_TOTAL_PAGES', data.totalPages);

                return data;
            }
        } catch (e) {
            console.error(e);
        }finally {
            dispatch("app/updateIsBusy", false, { root: true });
        }
    },

    async createAllVoucherReport({commit, dispatch}) {
        try {
            dispatch("app/updateIsBusy", true, { root: true });
            const {data} = await fetchData.post(`/CreateReport?all_vouchers=true`, '');
            if (data) {
                return data;
            }
        } catch (e) {
            console.error(e);
        }finally {
            dispatch("app/updateIsBusy", false, { root: true });
        }
    },

    async createReport({commit, dispatch}, { vouchers, voucher_list, appService = false }) {
        try {
            dispatch("app/updateIsBusy", true, { root: true });
            const payload = { vouchers, voucher_list, date_from: formatDateToISO(), date_to: formatDateToISO() }
            const body = JSON.stringify(payload);

            if (appService) {
                const { data } = await appserviceFetchData.post(`/reports`, body);

                return data;
            }

            const { data } = await fetchData.post("/CreateReport", body);

            return data;
        } catch (e) {
            console.error(e);
        }finally {
            dispatch("app/updateIsBusy", false, { root: true });
        }
    },

    /* eslint-disable */
    async deleteReport({commit, dispatch}, {payload, appService}) {
        try {
            dispatch("app/updateIsBusy", true, { root: true });
            const {id, reportNumbers} = payload;

            if(appService){
                const {data} = await appserviceFetchData.delete(`/reports/${id}`);
                return data;
            }

            const body = JSON.stringify({reportNumbers});
            const {data} = await fetchData.post("/DeleteReport", body);
            if (data) {
                return data;
            }
        } catch (e) {
            console.error(e);
        }finally {
            dispatch("app/updateIsBusy", false, { root: true });
        }
    },

    async deleteVoucher({commit, dispatch}, {id, position}) {
        try {
            dispatch("app/updateIsBusy", true, { root: true });
            const {data} = await appserviceFetchData.delete(`/reports/${id}/positions/${position}`);
            if (data) {
                return data;
            }
        } catch (e) {
            console.error(e);
        }finally {
            dispatch("app/updateIsBusy", false, { root: true });
        }
    },

    async deleteVouchers({commit, dispatch}, reportNumber) {
        try {
            dispatch("app/updateIsBusy", true, { root: true });
            const body = JSON.stringify(reportNumber);
            const {data} = await fetchData.post("/ReportDeleteVouchers", body);
            if (data) {
                return data;
            }
        } catch (e) {
            console.error(e);
        }finally {
            dispatch("app/updateIsBusy", false, { root: true });
        }
    },
};
