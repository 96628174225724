import moment from "moment";

const isValidDate = (date) =>{
    if(date === undefined || date === null){
        return false;
    }

    const momentDate = moment(date);

    if(momentDate.format("YYYY-MM-DD") === "1970-01-01"){
        return false;
    }

    return momentDate.isValid();
}

const formatDateToISO = (date) =>{
    if(isValidDate(date)){
        return moment(date).format("YYYY-MM-DDTHH:mm:ssZ");
    }

    return moment().format("YYYY-MM-DDTHH:mm:ssZ");
}

const formatDate = (date, format = null) =>{
    let dateTimeFormatString = "DD.MM.YYYY";

    if(format){
        dateTimeFormatString = format;
    }

    if(isValidDate(date)){
        return moment(date).format(dateTimeFormatString);
    }

    return;
}

export {formatDate, isValidDate, formatDateToISO};
