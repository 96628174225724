// @todo - jak będzie wyglądać sell_pipe dla innych serwisów
// @todo - czy są uwzględnione boxy?
const normalizeVoucherList = (inputData) => {
  return inputData.map(item => {
    return {
      id: item.id,
      id_voucher: item.id,
      source: 'internal',
      hash: item.hash,
      crc_hash: null,
      base_price: item.base_price,
      currency: 'PLN',
      tax_rate: null,
      provision: parseFloat(item.provision).toFixed(2),
      sell_pipe: item['sell_pipe'],
      image: null,
      create_date: item['date_status_realized'],
      planned_date: null,
      activation_date: null,
      status: 'terminated',
      status_date: null,
      expire_date: null,
      product_name: item.product_name,
      type: null,
      id_supplier: null,
      reservation_product: null,
      isAppService: true
    }
  })
}

const normalizeRealizedVouchersList = (data) => {
  try {
    return {
      items: normalizeVoucherList(data.result),
      page: data['result_info']['page'],
      pageSize: data['result_info']['per_page'],
      totalPages: Math.ceil(data['result_info']['total_count'] / data['result_info']['per_page']),
      itemsCount: data['result_info']['count'],
      itemsInternalCount: data['result_info']['total_count']
    }
  } catch (e) {
    return {
      items: [],
      page: 1,
      pageSize: 20,
      totalPages: 1,
      itemsCount: 0,
      itemsInternalCount: 0
    }
  }
}
export { normalizeRealizedVouchersList }
