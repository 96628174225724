<template>
  <div>
    <v-system-bar :height="60 + safeArea.top" color="emotivo" dense dark app flat>
      <v-container class="d-flex justify-center" fluid>
        <img src="@/assets/emotivo_logo.svg" alt="logo" width="169" height="37"/>
      </v-container>
    </v-system-bar>
    <v-main>
      <v-container class="fill-height justify-center" fluid>
        <slot />
      </v-container>
    </v-main>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  components: {},
  props:{},

  data() {
    return {};
  },

  watch: {},
  computed:{
    ...mapGetters({
      safeArea: 'app/safeArea',
    })
  },
  methods: {},
  mounted() {}

};
</script>
<style lang="scss" scope>
#emotv-logo {
  padding-top: var(--safe-area-top) !important;
}
</style>
