import { REPORT_CODE_STATUSES, REPORT_STATUSES_DICTIONARY } from '@/constants/report'

function isNumber(value) {
  return typeof value === 'number' && !isNaN(value)
}

export function isReportCreated(status) {
  if (isNumber(parseInt(status))) {
    return parseInt(status) === REPORT_STATUSES_DICTIONARY['CREATED']
  }

  return status === REPORT_CODE_STATUSES[1];
}

export function isReportSend(status) {
  if (isNumber(parseInt(status))) {
    return parseInt(status) === REPORT_STATUSES_DICTIONARY['SEND']
  }

  return status === REPORT_CODE_STATUSES[2];
}

export function isReportAccepted(status) {
  if (isNumber(parseInt(status))) {
    return parseInt(status) === REPORT_STATUSES_DICTIONARY['ACCEPTED']
  }

  return status === REPORT_CODE_STATUSES[3];
}

export function isReportDeleted(status) {
  if (isNumber(parseInt(status))) {
    return parseInt(status) === REPORT_STATUSES_DICTIONARY['DELETED']
  }

  return status === REPORT_CODE_STATUSES[4];
}

