import {getCookie, setCookie, removeCookie} from "@/helpers/cookies";
import {
    getModalMessage,
    getConstant,
    truncate,
    getDefaultDate,
    isIos,
    isAndroid,
    isInStandaloneMode,
    tokenIat,
    tokenExp,
    tokenExpIn, getImageURL, isInstalled, toFixed, isPimShop, isProduction, plural, isNativePlatform
} from "@/helpers/global";
import {getVoucherMessage, getVoucherStatusName} from "@/helpers/voucher";
import {formatDate, isValidDate} from "@/helpers/date";
import { isReportCreated, isReportAccepted, isReportSend, isReportDeleted } from '@/helpers/report'

export default {
    install(Vue) {
        Vue.prototype.$helper = {
            getModalMessage,
            isReportCreated,
            isReportAccepted, isReportSend, isReportDeleted,
            plural,
            isProduction,
            isPimShop,
            isValidDate, formatDate,
            getConstant,
            getCookie, setCookie, removeCookie,
            truncate, getDefaultDate,
            isIos, isAndroid, isInStandaloneMode,
            tokenIat, tokenExp, tokenExpIn,
            getImageURL,
            isInstalled, toFixed,
            getVoucherMessage, getVoucherStatusName, isNativePlatform
        };
    },
};
