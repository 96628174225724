import { SafeArea } from 'capacitor-plugin-safe-area'
import { Device } from '@capacitor/device';
import { mapMutations } from 'vuex'

export default {
  data() {
    return {
      safeArea: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      }
    }
  },

  computed: {},

  async mounted() {
    const info = await Device.getInfo();

    if (info.platform !== 'ios') {
      return;
    }

    SafeArea.getSafeAreaInsets().then(({ insets }) => {
      this.safeArea = insets
      this.SET_SAFE_AREA(insets)
      this.cssVars()
    })

    await SafeArea.removeAllListeners()
    await SafeArea.addListener('safeAreaChanged', data => {
      const { insets } = data
      this.safeArea = insets
      this.SET_SAFE_AREA(insets)
      this.cssVars()
    })
  },

  methods: {
    ...mapMutations({
      SET_SAFE_AREA: 'app/SET_SAFE_AREA'
    }),

    cssVars() {
      for (const [key, value] of Object.entries(this.safeArea)) {
        document.body.style.setProperty(`--safe-area-${key}`, `${value}px`)
      }
    }
  }
}
