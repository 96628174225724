const {fetchData} = require('@/helpers/featch');

export const state = {
    calendar: {},
};

export const getters = {
    voucherStatus: state => state.voucherStatus,
};

export const mutations = {
    SET_VOUCHER_STATUS(state, data) {
        state.voucherStatus = data;
    }
};

export const actions = {
    /* eslint-disable */
    async getCalendar({commit, dispatch}, payload) {
        try {
            dispatch("app/updateIsBusy", true, { root: true });
            const params = new URLSearchParams(payload).toString();
            const { data } = await fetchData.post("/GetCalendar", {}, params);

            if (data) {

                return data;
            }

            return false;
        } catch (e) {
            console.log(e);
        }finally {
            dispatch("app/updateIsBusy", false, { root: true });
        }
    },
};
